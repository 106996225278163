<template>
  <div id="intro">
    <div class="section">
      <h1>{{ title || "" }}</h1>

      <a
        :href="appointExplorerLink(hash, currency, 'tx')"
        class="minted"
        target="_blank"
        v-if="minted"
        >Minted on {{ timestampToCustomString(minted, "long") }}</a
      >

      <div class="meta" v-if="showTools">
        <div class="tools">
          <router-link :to="editLink" class="svg edit">
            <svg><use xlink:href="#svg-edit" /></svg>
          </router-link>

          <div class="svg delete" @click="$emit('deleteModal')">
            <svg><use xlink:href="#svg-delete" /></svg>
          </div>
        </div>
      </div>

      <SortFilter v-if="showSortFilter" :items="sortItems" @change="setSort" />
    </div>
  </div>
</template>

<script>
import SortFilter from "@/components/filters/SortFilter";
import timestampToCustomStringMixin from "@/mixins/timestampToCustomStringMixin";
import appointExplorerLinkMixin from "@/mixins/appointExplorerLinkMixin";

export default {
  name: "PageIntro",
  mixins: [timestampToCustomStringMixin, appointExplorerLinkMixin],
  props: {
    showSortFilter: {
      type: Boolean,
      required: true,
    },
    sortItems: {
      type: Array,
    },
    title: {
      type: String,
      required: false,
    },
    minted: {
      type: Number,
      required: false,
    },
    currency: {
      required: false,
    },
    hash: {
      required: false,
    },
    showTools: {
      type: Boolean,
      required: false,
    },
    editLink: {
      type: String,
      required: false,
    },
  },
  methods: {
    setSort(val) {
      this.$emit("setSort", val);
    },
  },
  components: { SortFilter },
};
</script>
