<template>
  <div class="sorter" v-click-outside="hide">
    <div class="label">{{ title }}</div>
    <div class="select" :class="{ active: showSelectItems }" @click="showSelectItems = !showSelectItems">
      <input type="hidden" name="sort" value="" />
      <div class="title">{{ current }}</div>
      <div class="options">
        <span
          v-for="(item, index) in items"
          :key="index"
          @click="change(item)"
        >{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import clickOutsideMixin from "@/mixins/clickOutsideMixin";

export default {
  name: "SortFilter",
  mixins: [clickOutsideMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: () => "Sort by",
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    showSelectItems: false,
    current: null,
  }),
  methods: {
    hide() {
      this.showSelectItems = false;
    },
    change(val) {
      this.current = val.text;
      this.$emit("change", val.value);
    },
    init() {
      this.current = this.items[0].text;
      this.$emit("init", this.items[0].value);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
