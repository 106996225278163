<template>
  <span :class="[wrapClass, { mono: username === null }]">
    <template v-if="username">@{{ username }}</template>
    <template v-else>{{ truncate(userAddress, 8) }}</template>
  </span>
</template>

<script>
import truncateMixin from "@/mixins/truncateMixin";

export default {
  name: "Username",
  mixins: [truncateMixin],
  props: {
    wrapClass: {
      type: String,
      required: false
    },
    username: {
      type: String,
    },
    userAddress: {
      required: true,
    }
  }
};
</script>
