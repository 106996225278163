<template>
  <div>
    <PageIntro
      title="Collections"
      :show-sort-filter="true"
      :sort-items="sortItems"
      @setSort="setSort"
    />

    <div id="stack" class="tiles section">
      <div class="flex">
        <CollectionCard
          v-for="(item, index) in items"
          :key="index"
          :collection="item"
        />
      </div>
      <ScrollLoader
        :loader-method="getNftCollections"
        :loader-disable="disableLoading"
        ><img width="200" height="200" src="@/assets/img/preloader.svg" />
      </ScrollLoader>
      <NoRecordsFound v-if="!items.length && !loading" />
    </div>
  </div>
</template>

<script>
import PageIntro from "../components/common/PageIntro";
import CollectionCard from "../components/common/CollectionCard";
import resetPrevLoadingMixin from "@/mixins/resetPrevLoadingMixin";
import setSortMixin from "@/mixins/setSortMixin";
import NoRecordsFound from "@/components/common/NoRecordsFound";
import getCollectionsMixin from "@/mixins/collection/getCollectionsMixin";

export default {
  name: "Collections",
  mixins: [resetPrevLoadingMixin, setSortMixin, getCollectionsMixin],
  data: () => ({
    sortItems: [
      {
        text: "Date minted to oldest",
        value: "created,desc",
      },
      {
        text: "Date minted to newest",
        value: "created,asc",
      },
    ],
  }),
  watch: {
    $route(val) {
      if (val.query && val.query.removed) {
        this.items = this.items.filter((i) => i.id !== val.query.removed);
        this.$router.push({
          path: this.$route.path,
          query: {},
        });
      }
    },
  },
  components: {
    NoRecordsFound,
    PageIntro,
    CollectionCard,
  },
};
</script>
