import errors from "@/util/errors";

export default {
  computed: {
    collectionId() {
      return this.collection.id;
    },
    collectionOwners() {
      return this.collection.owners;
    },
    collectionVolumeTraded() {
      return this.collection.volumeTraded;
    },
    collectionFloorPrice() {
      return this.collection.floorPrice;
    },
    collectionItems() {
      return this.collection.items;
    },
    collectionImageUrl() {
      return this.collection.fileUrl;
    },
    collectionTitle: {
      get() {
        return this.collection.title;
      },
      set(val) {
        this.titleError = null;
        if (!val) {
          this.titleError = this.$t(errors.REQUIRED_FIELD);
        }
        this.collection.title = val;
      },
    },
    collectionDescription: {
      get() {
        return this.collection.description;
      },
      set(val) {
        this.descriptionError = null;
        if (!val) {
          this.descriptionError = this.$t(errors.REQUIRED_FIELD);
        }
        if (val && val.length > 500) {
          this.descriptionError = `${this.$t(errors.MAX_LENGTH_ERROR)} 500`;
        }
        this.description = val;
      },
    },
    collectionOwnerAddress() {
      return this.collection.owner.address;
    },
    collectionOwnerId() {
      return this.collection.owner.id;
    },
    collectionOwnerAvatar() {
      return this.collection.owner.userInfo.avatarUrl;
    },
    collectionOwnerNickname() {
      return this.collection.owner.nickname;
    },
    collectionOwnerName() {
      return this.collection.owner.userInfo.name || "";
    },
    collectionOwnerSurname() {
      return this.collection.owner.userInfo.surname || "";
    },
    collectionOwnerFullName() {
      return `${this.collectionOwnerName} ${this.collectionOwnerSurname}`;
    },
  },
};
