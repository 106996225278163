export default {
  data: () => ({
    sort: "created,desc",
  }),
  methods: {
    setSort(val) {
      this.sort = val;
      this.resetPrevLoading();
    },
  },
};
