export default {
  methods: {
    timestampToCustomString(stamp, type) {
      const shortMonthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const d = new Date(stamp);
      switch (type) {
        case "long":
          return `${shortMonthNames[d.getMonth()].substring(
            0,
            3
          )} ${d.getDate()}, ${d.getFullYear()}`;
        case "long-time":
          return `${shortMonthNames[d.getMonth()].substring(
            0,
            3
          )} ${d.getDate()}, ${d.getFullYear()} at ${d.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}`;
        case "short":
          return `${shortMonthNames[d.getMonth()]} ${d.getFullYear()}`;
      }
    },
  },
};
