import networks from "../util/networks";

export default {
  methods: {
    appointExplorerLink(hash, currency, type) {
      switch (currency) {
        case "ETH":
          return `${networks.find((i) => i.symbol === "ETH")}/${type}/${hash}`;
        case "MATIC":
          return `${
            networks.find((i) => i.symbol === "MATIC").blockExplorerUrl
          }/${type}/${hash}`;
      }
      return "#";
    },
  },
};
