import api from "@/api/api";

export default {
  data: () => ({
    page: 0,
    size: 12,
    total: 0,
    items: [],
    loading: false,
    disableLoading: false,
  }),
  methods: {
    async getNftCollections() {
      try {
        this.loading = true;
        const response = await api.getNftCollections({
          page: this.page++,
          size: this.size,
          sort: this.sort,
        });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
        this.total = response.totalElements;
      } catch {
        this.disableLoading = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
