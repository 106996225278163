<template>
  <div class="no-records">
    <span class="svg">
      <svg><use xlink:href="#svg-no-records" /></svg>
    </span>
    <span>No Records Found</span>
  </div>
</template>

<script>
export default {
  name: "NoRecordsFound",
};
</script>
