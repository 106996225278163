import ClickOutside from "vue-click-outside";

export default {
  mounted() {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el;
  },
  directives: {
    ClickOutside,
  },
};
