export default {
  methods: {
    truncate(string, length) {
      if (!string) {
        return "";
      }
      if (string.length <= length) {
        return string;
      }
      return `${string.substring(0, length)}...`;
    },
  },
};
