<template>
  <div class="coli-tile">
    <div class="preview">
      <img v-lazy="collectionImageUrl" :alt="collectionTitle" />
    </div>

    <router-link :to="collectionLink(collectionId)" class="coli-title">
      <span>{{ collectionTitle }}</span>
    </router-link>

    <UsernameLink
      :user-id="collectionOwnerId"
      :username="collectionOwnerNickname"
      :user-address="collectionOwnerAddress"
      :wrap-class="'coli-creator'"
    />
  </div>
</template>

<script>
import UsernameLink from "@/components/common/UsernameLink";
import collectionInfoMixin from "@/mixins/collectionInfoMixin";
import useLinkMixin from "@/mixins/useLinkMixin";
export default {
  name: "CollectionCard",
  mixins: [collectionInfoMixin, useLinkMixin],
  components: { UsernameLink },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
};
</script>
