<template>
  <router-link :to="profileLink(userId)" :class="wrapClass">
    <Username :username="username" :user-address="userAddress" />
  </router-link>
</template>

<script>
import Username from "@/components/common/Username";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "UsernameLink",
  mixins: [useLinkMixin],
  props: {
    wrapClass: {
      type: String,
      required: false,
    },
    username: {
      type: String,
    },
    userId: {
      required: true,
    },
    userAddress: {
      required: true,
    },
  },
  components: { Username },
};
</script>
