import { mapGetters } from "vuex";
import routePaths from "@/util/routePaths";

export default {
  computed: {
    ...mapGetters({
      getLanguage: "language/getLanguage",
    }),
  },
  methods: {
    homeLink() {
      return "/";
    },
    creatorsLink() {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.CREATORS_PATH
      }`;
    },
    settingsLink() {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.SETTINGS_PATH
      }`;
    },
    collectionsLink() {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.COLLECTIONS_PATH
      }`;
    },
    createLink() {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.CREATE_PATH
      }`;
    },
    artworksLink() {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.ARTWORKS_PATH
      }`;
    },
    signInLink() {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.SIGN_IN_PATH
      }`;
    },
    privacyPolicyLink() {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.PRIVACY_POLICY_PATH
      }`;
    },
    termsOfServiceLink() {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.TERMS_OF_SERVICE_PATH
      }`;
    },
    activityLink(tab) {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.ACTIVITY_PATH
      }/${tab}`;
    },
    collectionLink(id) {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.COLLECTIONS_PATH
      }/${id}`;
    },
    editCollectionLink(id) {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.EDIT_COLLECTION_PATH
      }/${id}`;
    },
    profileLink(id, tab = "bio") {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.PROFILE_PATH
      }/${id}/${tab}`;
    },
    editNftLink(id, currency, contract = "") {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.NFT_EDIT_PATH
      }/${currency}${contract ? `/${contract}` : ""}/${id}`;
    },
    nftLink(id, currency, contract = "") {
      return `/${this.getLanguage ? `${this.getLanguage}/` : ""}${
        routePaths.NFT_PATH
      }/${currency}${contract ? `/${contract}` : ""}/${id}`;
    },
  },
};
